import { TOUR_MANAGEMENTS_ROUTES } from './../modules/tour-ota/components/tour-managements/tour-managements.routes';
export enum AppRoutes {
  HOME = 'home',
  SEARCH_RESULT = 'search-result',
  CREATE_TOUR = 'create',
  UPDATE_TOUR = 'update',
  TOUR = 'tour',
  HOTEL = 'hotel',
  TOUR_GUIDE = 'tour-guide',
  JOURNEY = 'journey',
  BOOKING = 'booking',
  FOOD = 'food',
  CAR = 'car',
  SHOPPING = 'shopping',
  LOGIN = 'login',
  TOUR_LIST = 'search',
  QUICK_VIEW = 'qview',
  TOUR_DETAIL = 'tour-detail',
  PLACE_DETAIL = 'place-detail',
  MY_TOUR = 'my-tour',
  MY_PLACE = 'my-place',
  MY_BOOKING = 'my-bookings',
  MY_BOOKING_DETAIL = 'my-booking-detail',
  FILTER = 'filter',
  RECOMMENDED_PLACES = 'recommended-places',
  SAVED_LIST = 'saved-list',
  BROWSE_ALL = 'browse-all',
  EXPLORE = 'explore',
  REVIEW = 'review-tour',
  RESTAURANT = 'restaurant',
  MEDIA_PREVIEW = 'media-preview',
  AGENCY = 'agency',
  PAYMENT = 'payment',
  BILL_DETAILS = 'bill-details',
  TOUR_AVAILABILITY = 'availability',
  TOUR_PROMOTION = 'promotion',
  REQUEST_BECOME_AGENCY = 'request-become-agency',
  TOUR_MANAGEMENTS = 'tour-managements',
  REVIEW_MANAGEMENT = 'review-management',
  TOUR_MANAGE = 'tour',
  TOUR_RESERVATION = 'reservation',
  TOUR_AFF = 'affiliate',
  TOUR_EDIT = 'edit',
  DETAILS = 'details',
  GENERAL_INFO = 'general-info',
  USERS = 'user',
  PAYMENT_TYPE = 'payment-type',
  GROUP_TOUR_MANAGEMENT = 'group-tour-management',
  CONTRACTS = 'contract',
  POLICIES = 'policy',
  CONTACTS = 'contact',
  GROUP_TOURS = 'group',
  BRANCH = 'branch',
  TOUR_DASHBOARD = 'dashboard',
  TOUR_GENERAL_INFO = 'general-info',
  TOUR_PAYMENT_INFO = 'payment-info',
  DEACTIVATE_TOUR = 'deactivate-tour',
  APPROVAL_REVIEW = 'approval-review',
  PROFILE = 'profile',
  AGENCY_CENTER = 'agency-center',
  ACCOUNT_BALANCE = 'account-balance',
  SIGN_UP = 'sign-up',
  AGENCY_SUPPORT = 'agency-support',
  HOT_DEALS = 'hot-deals',
  ADVENTURE_STYLE = 'adventure-styles',
  REGION = 'region',
  TOUR_LABEL = 'tour-label',
}
