<p-confirmDialog
  appResize
  styleClass="md:min-w-[500px] w-full md:w-[500px] t-maptour-confirm-dialog"
  appendTo="body"
  [position]="position"
  (isDesktopViewChange)="onIsDesktopViewChange($event)"
></p-confirmDialog>
<div class="t-map-container" (click)="closePopup()">
  <div class="map-frame">
    <app-soctrip-map
      [centerLat]="initCoordinate.lat"
      [centerLng]="initCoordinate.lon"
      [zoom]="10"
      (mapGen)="initMapLayer($event)"
      [typeMap]="typeMap"
    >
    </app-soctrip-map>
  </div>

  <div>
    <app-modal
      appendTo="body"
      [showDialog]="isCreatePlace"
      (emitOnCloseDialog)="onCancel(); removeAddedMarker()"
      [dialogConfig]="dialogConfig"
    >
      <div modal-header>
        <div class="flex flex-col w-full gap-1">
          <h1 *ngIf="action === 'create'" class="text-gray-900 font-semibold text-xl">
            {{ 'common.component.map_tour.add_a_place' | translateAsync }}
          </h1>
          <h1 *ngIf="action === 'edit'" class="text-gray-900 font-semibold text-xl">
            {{ 'common.component.map_tour.edit_place' | translateAsync }}
          </h1>
          <p modal-header-desc class="text-md text-gray-600">
            {{ 'common.component.map_tour.provide_some_info' | translateAsync }}
            {{ 'common.component.map_tour.map' | translateAsync }}
          </p>
        </div>
      </div>

      <form [formGroup]="addPlaceForm" class="flex flex-col gap-4 pb-4" modal-content>
        <div class="flex flex-col gap-1.5">
          <div class="flex gap-1 text-md">
            <h4 class="font-medium text-gray-700">
              {{ 'common.component.map_tour.name' | translateAsync }}
            </h4>
            <span class="text-error-600">*</span>
          </div>
          <input
            class="w-full h-11 t-create-place-input"
            placeholder="{{ 'common.component.map_tour.enter_name' | translateAsync }}"
            type="text"
            pInputText
            formControlName="name_text"
          />
          <div *ngIf="invalidType('name_text')" class="text-red-600">
            {{ 'common.component.map_tour.invalid_name_type' | translateAsync }}
          </div>
          <div
            *ngIf="
              addPlaceForm.get('name_text')?.invalid &&
              (addPlaceForm.get('name_text')?.dirty || addPlaceForm.get('name_text')?.touched)
            "
          >
            <div *ngIf="addPlaceForm.get('name_text')?.hasError('required')" class="text-red-600">
              {{
                'common.term.param_is_required'
                  | translateAsync: { param: 'common.component.map_tour.name' | translateAsync }
              }}
            </div>
          </div>
        </div>
        <!-- <div class="flex flex-col gap-1.5">
          <div class="flex gap-1 text-md">
            <h4 class="font-medium text-gray-700">Category</h4>
            <span class="text-error-600">*</span>
          </div>
          <p-autoComplete
            class="t-autocomplete-cat"
            [(ngModel)]="SelectedCategory"
            [group]="true"
            [suggestions]="filteredCategorys"
            field="label"
            [dropdown]="true"
            placeholder="Add Category"
            styleClass="w-full"
            [forceSelection]="true"
            panelStyleClass="w-full "
            formControlName="category"
            (completeMethod)="filterGroupedCategory($event)"
            (onFocus)="handleOnFocus($event)"
            (onSelect)="handleOnSelect($event)"
          >
            <ng-template let-group pTemplate="group">
              <div class="flex align-items-center">
                <span>{{ group.label }}</span>
              </div>
            </ng-template>
          </p-autoComplete>
          <div
            *ngIf="
              addPlaceForm.get('category')?.invalid &&
              (addPlaceForm.get('category')?.dirty || addPlaceForm.get('category')?.touched)
            "
          >
            <div *ngIf="addPlaceForm.get('category')?.hasError('required')" class="text-red-600">
              Category is required.
            </div>
          </div>
        </div> -->
        <div class="flex flex-col gap-1.5">
          <div class="flex gap-1 text-md">
            <h4 class="font-medium text-gray-700">
              {{ 'common.component.map_tour.category' | translateAsync }}
            </h4>
            <span class="text-error-600">*</span>
          </div>
          <p-treeSelect
            class="t-select-tree w-full md:w-20rem"
            containerStyleClass="w-full"
            [(ngModel)]="selectedNodes"
            [options]="nodes"
            [metaKeySelection]="false"
            placeholder="{{ 'common.component.map_tour.add_category' | translateAsync }}"
            [filter]="true"
            [filterInputAutoFocus]="true"
            formControlName="category"
            [resetFilterOnHide]="true"
            filterPlaceholder="{{ 'common.component.map_tour.search' | translateAsync }}"
            scrollHeight="320px"
          ></p-treeSelect>
          <div *ngIf="addPlaceForm.get('category')?.hasError('required')" class="text-red-600">
            {{
              'common.term.param_is_required'
                | translateAsync: { param: 'common.component.map_tour.category' | translateAsync }
            }}
          </div>
        </div>
        <div class="flex flex-col gap-1.5">
          <div class="flex gap-1 text-md">
            <h4 class="font-medium text-gray-700">
              {{ 'common.component.map_tour.address' | translateAsync }}
            </h4>
            <span class="text-error-600">*</span>
          </div>
          <input
            class="w-full h-11 t-create-place-input"
            placeholder="{{ 'common.component.map_tour.enter_address' | translateAsync }}"
            type="text"
            pInputText
            formControlName="addr"
          />
          <div *ngIf="invalidType('addr')" class="text-red-600">
            {{ 'common.component.map_tour.invalid_address_type' | translateAsync }}
          </div>
          <div
            *ngIf="
              addPlaceForm.get('addr')?.invalid &&
              (addPlaceForm.get('addr')?.dirty || addPlaceForm.get('addr')?.touched)
            "
          >
            <div *ngIf="addPlaceForm.get('addr')?.hasError('required')" class="text-red-600">
              {{
                'common.term.param_is_required'
                  | translateAsync: { param: 'common.component.map_tour.address' | translateAsync }
              }}
            </div>
          </div>
        </div>

        <div class="flex flex-col gap-1.5">
          <div class="flex gap-1 text-md">
            <h4 class="font-medium text-gray-700">
              {{ 'common.component.map_tour.headline' | translateAsync }}
            </h4>
          </div>
          <input
            class="w-full h-11 t-create-place-input"
            placeholder="{{ 'common.component.map_tour.enter_headline' | translateAsync }}"
            type="text"
            pInputText
            formControlName="secd_text"
          />
        </div>
        <div class="flex flex-col gap-1.5">
          <h4 class="text-md font-medium text-gray-700">
            {{ 'common.component.map_tour.description' | translateAsync }}
          </h4>
          <textarea
            class="w-full t-create-place-input"
            rows="5"
            pInputTextarea
            formControlName="desc"
            placeholder="{{ 'common.component.map_tour.enter_description' | translateAsync }}"
          >
          </textarea>
        </div>
        <div class="flex flex-col md:flex-row gap-4 md:gap-6">
          <div class="w-full flex flex-col gap-1.5">
            <h4 class="text-md font-medium text-gray-700">
              {{ 'common.component.map_tour.latitude' | translateAsync }}
            </h4>
            <input
              class="w-full h-11 t-create-place-input"
              type="text"
              pInputText
              [value]="lat"
              placeholder="{{ 'common.component.map_tour.enter_latitude' | translateAsync }}"
              disabled
            />
          </div>
          <div class="w-full flex flex-col gap-1.5">
            <h4 class="text-md font-medium text-gray-700">
              {{ 'common.component.map_tour.longitude' | translateAsync }}
            </h4>
            <input
              class="w-full h-11 t-create-place-input"
              type="text"
              pInputText
              [value]="lng"
              placeholder="{{ 'common.component.map_tour.enter_longitude' | translateAsync }}"
              disabled
            />
          </div>
        </div>
        <div class="flex flex-col gap-1.5">
          <h4 class="text-md font-medium text-gray-700">
            {{ 'common.component.map_tour.upload_image' | translateAsync }}
            <span class="text-error-600">*</span>
          </h4>

          <app-multi-file-uploader
            [isReset]="isResetImageSelect"
            [acceptedFileTypes]="['svg', 'png', 'jpg', 'gif']"
            [acceptedFileTypesText]="'SVG, PNG, JPG or GIF (max 800x400px)'"
            (filesSelected)="onImageSelected($event)"
            [listImageUrl]="listImageUrl"
            (clearFileSelected)="clearImageSelected($event)"
            [action]="action"
          >
          </app-multi-file-uploader>

          <div
            *ngIf="listImageUrl.length === 0 && selectedFiles === undefined"
            class="text-red-600"
          >
            {{
              'common.term.param_is_required'
                | translateAsync: { param: 'common.component.map_tour.image' | translateAsync }
            }}
          </div>
        </div>
        <div class="flex items-center gap-2">
          <p-checkbox
            formControlName="publish"
            inputId="publish_banner"
            value="true"
            [binary]="true"
          ></p-checkbox>
          <label for="publish_banner" class="text-md font-medium text-gray-700">{{
            'common.component.map_tour.publish_this_place' | translateAsync
          }}</label>
        </div>
      </form>
      <div class="t-create-dialog-footer border-none" modal-footer>
        <div class="flex flex-row gap-3 justify-end mr-4">
          <button
            class="t-footer-btn min-w-[90px] h-11 leading-[44px] px-[18px] bg-white text-base font-semibold text-gray-700 rounded-lg border border-solid border-gray-300"
            type="submit"
            (click)="onCancel(); removeAddedMarker()"
          >
            {{ 'common.term.cancel' | translateAsync }}
          </button>
          <button
            class="t-footer-btn min-w-[74px] h-11 leading-[44px] px-[18px] text-base font-semibold text-white rounded-lg border border-solid"
            type="submit"
            [disabled]="disableBtnSave()"
            (click)="onSubmit(); removeAddedMarker()"
            [ngClass]="{
              '!bg-primary-300 !border-primary-300': disableBtnSave(),
              '!bg-primary-600 !border-primary-600': !disableBtnSave()
            }"
          >
            {{ 'common.term.save' | translateAsync }}
          </button>
        </div>
      </div>
    </app-modal>
  </div>
  <div *ngIf="typeMap === 'osm'">
    <button
      aria-label="Search"
      class="sctr-icon-search-lg toggle-search-box t-icon--white {{ hideAction ? 'hidden' : '' }}"
      (click)="handleToggleSearchBox()"
      [pTooltip]="'common.component.map_tour.search_place' | translateAsync"
      tooltipPosition="bottom"
      tooltipStyleClass="t-map-tour-tool-tip"
    ></button>

    <!-- <button
      aria-label="Direction"
      class="sctr-icon-compass-03 toggle-direction-box t-icon--white {{
        hideAction ? 'hidden' : ''
      }}"
      (click)="handleToggleDirectionBox()"
      [pTooltip]="'common.component.map_tour.direction' | translateAsync"
      tooltipPosition="bottom"
      tooltipStyleClass="t-map-tour-tool-tip"
    ></button> -->

    <button
      aria-label="layers"
      class="toggle-layer-btn sctr-icon-layers-three-02 t-icon--white {{
        hideAction ? 'hidden' : ''
      }}"
      (click)="toggleDropdown()"
      [pTooltip]="'common.component.map_tour.layers' | translateAsync"
      tooltipPosition="bottom"
      tooltipStyleClass="t-map-tour-tool-tip"
    ></button>

    <div class="t-map-layers" [ngClass]="{ show: isDropdownOpen }">
      <div class="t-layer-header">
        <div class="t-layer-text">
          {{ 'common.component.map_tour.map_layers' | translateAsync }}
        </div>
        <div class="t-layer-btn">
          <button class="btn btn-sm btn-primary mr-2" (click)="onSelectAllLayer()">
            {{ 'common.component.map_tour.select_all' | translateAsync }}
          </button>
          <button class="btn btn-sm btn-primary" (click)="onClearAllLayer()">
            {{ 'common.component.map_tour.clear_all' | translateAsync }}
          </button>
        </div>
      </div>
      <div class="t-layer-content">
        <div class="t-layer-title">
          {{ 'common.component.map_tour.categories' | translateAsync }}
        </div>

        <div class="t-layer-items">
          <div *ngFor="let category of mapTourService.categoriesPlace" class="t-layer-item">
            <div class="flex items-center">
              <img class="w-[40px] h-[30px]" [src]="category.img" /><img />
              <label class="text-gray-700" [for]="category.key">{{
                'map.categories.' + category.key | translateAsync
              }}</label>
            </div>
            <p-checkbox
              name="group"
              [value]="category"
              [(ngModel)]="selectedCategoriesPlace"
              [inputId]="category.key"
              (ngModelChange)="onSelectedCategoriesPlaceChange()"
            ></p-checkbox>
          </div>
        </div>
      </div>
    </div>
    <div class="t-map-search-container {{ isShowSeachBox ? 'active' : '' }}">
      <app-map-search [isShowSeachBox]="isShowSeachBox" (selectItemCallback)="onSelectItem($event)">
      </app-map-search>
    </div>
  </div>
  <!-- <app-map-routing
    [sidebarVisible]="isShowDirectionBox"
    (hideSidebar)="hideDirectionBox()"
  ></app-map-routing> -->
</div>
