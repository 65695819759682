import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
  inject,
} from '@angular/core';
import { CommonModule, ViewportScroller } from '@angular/common';
import { Router, RouterOutlet } from '@angular/router';
import { HeaderComponent } from '@layout/header/header.component';
import { FooterComponent } from '@layout/footer/footer.component';
import { QuickViewComponent } from '@modules/tour-list/components/quick-view/quick-view.component';
import { OverviewComponent } from '@modules/tour-list/components/overview/overview.component';
import { MapTourComponent } from '@shared/components/map-tour/map-tour.component';
import { AdvancedFilterComponent } from '@modules/tour-list/components/advanced-filter/advanced-filter.component';
import { RecommendedPlacesComponent } from '@modules/create-tour/components/recommended-places/recommended-places.component';
import { TourFormService } from '@modules/create-tour/services/tour-form.service';
import { DialogConfig } from '@shared/models/dialog';
import {
  ILocation,
  LocationService,
  SoctripMapModule,
  SoctripMapService,
} from '@soctrip-common/map';
import { ModalComponent } from '@shared/components/modal/modal.component';
import { GalleryComponent } from '@shared/components/gallery/gallery.component';
import { SideBarComponent } from '@modules/create-tour/components/side-bar/side-bar.component';
import { SidebarService } from '@modules/create-tour/services/sidebar.service';
import { AppRoutes } from 'src/app/config/routes.config';
import { Observable, of } from 'rxjs';
import { OpenMapService } from '@modules/create-tour/services/open-map.service';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ItineraryService } from '@shared/services/itinerary.service';
import { TourListService } from '@shared/services/tour-list.service';
import { ExpandHeaderService } from '@shared/services/expand-header.service';
import { TranslateModule } from '@ngx-translate/core';
import { TranslateAsyncPipe } from '@shared/pipes/translate-async.pipe';
import { environment } from '@env/environment';
import { LocalizedLanguageStringPipe } from '@shared/pipes/localized-language-string.pipe';

@Component({
  selector: 'app-tour-layout',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    HeaderComponent,
    FooterComponent,
    QuickViewComponent,
    OverviewComponent,
    MapTourComponent,
    AdvancedFilterComponent,
    RecommendedPlacesComponent,
    SoctripMapModule,
    ModalComponent,
    GalleryComponent,
    SideBarComponent,
    ScrollingModule,
    TranslateModule,
    TranslateAsyncPipe,
  ],
  templateUrl: './tour-layout.component.html',
  styleUrls: ['./tour-layout.component.scss'],
  providers: [LocalizedLanguageStringPipe],
})
export class TourLayoutComponent implements OnInit, AfterViewInit, OnDestroy {
  openMap: boolean = false;
  isMapVisible: boolean = false;
  showQuickView: boolean = false;
  showFilter: boolean = false;
  showRecPlaces: boolean = false;
  showMediaGallery: boolean = false;
  locationData!: ILocation | null;
  isSidebarOpened: boolean = false;
  isSidebarVisible: boolean = false;
  handleZIndex: boolean = false;
  map: any;
  @ViewChild('mapTour') mapTour: ElementRef;
  private router = inject(Router);

  mediaGalleryDialogConfig: DialogConfig = {
    closeFromOutSide: true,
    resizable: false,
    scrollable: false,
    displayCloseButton: true,
    styleClass: 't-gallery-images-modal',
    appendTo: 'body',
  };
  constructor(
    private locationService: LocationService,
    public sidebarService: SidebarService,
    public openMapService: OpenMapService,
    private viewmap: ViewportScroller,
    private mapService: SoctripMapService,
    private itineraryService: ItineraryService,
    private tourListService: TourListService,
    public expandHeaderService: ExpandHeaderService,
    private localizedLanguageStringPipe: LocalizedLanguageStringPipe
  ) {}
  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.checkWindowInnerWidth(event['target']!['innerWidth']);
  }
  ngOnInit(): void {
    this.tourListService.handleModalShareTour.subscribe((value) => (this.handleZIndex = value));
    this.scrollMap();
    this.checkWindowInnerWidth(window.innerWidth);
    if (
      this.router.url.startsWith(`/${environment.prefix}/${AppRoutes.CREATE_TOUR}`) ||
      this.router.url.includes(`/${AppRoutes.UPDATE_TOUR}`)
    ) {
      this.isSidebarVisible = true;
    }
    this.locationService.LocationDataObservable$.subscribe((location) => {
      this.locationData = location
        ? ({
            ...location,
            secd_text: this.localizedLanguageStringPipe.transform(location?.secd_text || ''),
          } as ILocation)
        : null;

      const tooltip = document.querySelector('.leaflet-tooltip.leaflet-zoom-animated');
      if (tooltip) {
        tooltip.remove();
      }
    });
    this.expandHeaderService.setExpandHeader(true);
  }
  ngAfterViewInit() {
    this.map = this.mapService.getMap();
  }
  ngOnDestroy() {
    this.expandHeaderService.setExpandHeader(false);
  }
  scrollMap() {
    this.openMapService.scrollMap.subscribe((value) => {
      if (value) {
        const map = document.getElementById('map-tour');
        setTimeout(() => {
          map?.scrollIntoView({ behavior: 'auto' });
        }, 300);
      }
    });
  }
  checkWindowInnerWidth(width: number) {
    if (width >= 768) {
      this.openMapService.open = false;
      this.openMapService.isVisible = true;
    } else {
      if (!this.openMapService.open) {
        this.openMapService.isVisible = false;
      }
    }
  }
  onShowQuickView(isShow: boolean) {
    this.showQuickView = isShow;
    this.itineraryService.iShowQuickView = isShow;
  }

  onShowFilter(isShow: boolean) {
    this.showFilter = isShow;
  }

  onShowRecPlaces(isShow: boolean) {
    this.showRecPlaces = isShow;
  }

  onCloseModal() {
    this.locationData = null;
  }

  onCloseLocationDialog(data: ILocation | null) {
    this.locationData = data;
  }

  onShowMediaGallery(isShow: boolean) {
    this.showMediaGallery = isShow;
  }

  onCloseMediaGallery() {
    this.showMediaGallery = false;
  }
}
