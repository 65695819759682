import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { CtaSigninService } from '@shared/services/cta-signin.service';
import { environment } from '@env/environment';
import { TranslateAsyncPipe } from '@shared/pipes/translate-async.pipe';
import { ActivatedRoute } from '@angular/router';
import { ClientSBannerControllerService } from '@soctrip/angular-banner-service';
import { TimezoneService } from '@shared/services/timezone.service';
import { ImagePathPipe } from '@shared/pipes/image-path.pipe';

@Component({
  selector: 'app-popup-cta',
  standalone: true,
  imports: [CommonModule, TranslateModule, TranslateAsyncPipe, ImagePathPipe],
  templateUrl: './popup-cta.component.html',
  styleUrls: ['./popup-cta.component.scss'],
})
export class PopupCtaComponent {
  pageTitle = environment.PAGE_TITLE;
  isApprovalPage: boolean = false;
  ctaURL: string | undefined;
  mediaId: any;

  constructor(
    public CtaSigninService: CtaSigninService,
    private route: ActivatedRoute,
    private clientSBannerService: ClientSBannerControllerService,
    private timezoneService: TimezoneService
  ) {
    this.route.url.subscribe((segments) => {
      const url = segments.join('/');
      this.isApprovalPage = url.startsWith('approval');
    });
    this.getSignInBanner();
  }

  handleClickClose(): void {
    this.CtaSigninService.hideCTA();
  }

  handleClickSignInOrSignUp(): void {
    // this.CtaSigninService.navigateToSignInPage();
    window.location.href = this.ctaURL
      ? this.ctaURL
      : ` ${environment.SOCIAL_APP_URL}/login?r=${window.location.origin}`;
  }
  getSignInBanner() {
    let currentCountryCode = localStorage.getItem('currentCountryCode') || 'US';

    let currentTimeZone = this.timezoneService.getTimezoneMapping(
      currentCountryCode.toLocaleUpperCase()
    );
    this.clientSBannerService
      .getBannerByCode('TRAVEL_TOUR_SIGNIN_POPUP_BANNERS', currentCountryCode, currentTimeZone)
      .subscribe((data) => {
        if (data && data.data && data.data.length > 0) {
          const bannerData = data.data[0];
          this.mediaId = bannerData?.medias?.[0]?.id?.toString()!;
          this.ctaURL = bannerData?.url;
        }
      });
  }
}
