import { ActivatedRouteSnapshot, RouterStateSnapshot, Routes } from '@angular/router';
import { TourLayoutComponent } from '@layout/tour-layout/tour-layout.component';
import { TasksService } from '@modules/todos/services/tasks.service';
import { PermissionGuard } from './core/guards/permission/permission.guard';
import { USER_PERMISSION, MANAGER_PERMISSION } from './shared';
import { AppRoutes } from './config/routes.config';
import { MainLayout } from '@modules/saved-list/components/main-layout/main-layout';
import { MainLayoutComponent } from '@layout/main-layout/main-layout.component';
import { OtaLayoutComponent } from '@layout/ota-layout/ota-layout.component';
import { inject } from '@angular/core';
import { UserRolesResolverService } from '@core/services/user/roles.service';
import { environment } from '@env/environment';
import { APP_PERMISSION } from '@core/constants/permissions';
import { AutoNavigateGuard } from '@core/guards/auto-navigate/auto-navigate.guard';
import { PagePermissionGuard } from '@core/guards/page-permission/page-permission.guard';
import { AuthGuard } from '@core/guards/auth/auth.guard';
import { TestingGuard } from '@core/guards/testing-permission/testing.guard';

export const APP_ROUTES: Routes = [
  {
    path: '',
    redirectTo: `${environment.prefix}`,
    pathMatch: 'full',
  },
  {
    path: `${environment.prefix}`,
    // canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: MainLayoutComponent,
        children: [
          {
            path: '',
            title: environment.PAGE_TITLE,
            pathMatch: 'full',
            loadComponent: () =>
              import('@modules/home/home/home.component').then(
                ({ HomeComponent }) => HomeComponent
              ),
          },
          {
            path: AppRoutes.SEARCH_RESULT,
            title: 'Search result',
            loadChildren: () =>
              import('./modules/search-result/search-result.routes').then(
                ({ SEARCH_RESULT_ROUTES }) => SEARCH_RESULT_ROUTES
              ),
          },
          {
            path: 'agencies',
            title: 'Agencies',
            loadComponent: () =>
              import(
                '@modules/tour-ota/components/agency-management/components/agencies/agencies.component'
              ).then(({ AgenciesComponent }) => AgenciesComponent),
          },
          {
            path: AppRoutes.HOME,
            title: 'Home',
            loadComponent: () =>
              import('@modules/home/home/home.component').then(
                ({ HomeComponent }) => HomeComponent
              ),
          },
          {
            path: `${AppRoutes.REGION}/:country-code/:localized-page-name`,
            title: 'Region tours',
            loadComponent: () =>
              import('./modules/region-tours/region-tours/region-tours.component').then(
                ({ RegionToursComponent }) => RegionToursComponent
              ),
          },
          {
            path: AppRoutes.HOT_DEALS,
            title: 'Hot deals',
            loadChildren: () =>
              import('./modules/hot-deal/hot-deal.routes').then(
                ({ HOT_DEAL_ROUTES }) => HOT_DEAL_ROUTES
              ),
          },
          {
            path: 'utils',
            title: 'Testing',
            canActivate: [TestingGuard],
            children: [
              {
                path: 'auto-translate-testing',
                loadComponent: () => {
                  return import(
                    './modules/auto-translate-testing/auto-translate-testing.component'
                  ).then(({ AutoTranslateTestingComponent }) => AutoTranslateTestingComponent);
                },
              },
            ],
          },
          {
            path: AppRoutes.ADVENTURE_STYLE,
            title: 'Adventure styles',
            loadChildren: () =>
              import('./modules/adventure-style/adventure-style.routes').then(
                ({ ADVENTURE_STYLE_ROUTES }) => ADVENTURE_STYLE_ROUTES
              ),
          },
          {
            path: AppRoutes.TOUR_LABEL,
            title: 'Tour label',
            loadChildren: () =>
              import('./modules/tour-label/tour-label.routes').then(
                ({ TOUR_LABEL_ROUTES }) => TOUR_LABEL_ROUTES
              ),
          },
          {
            path: AppRoutes.TOUR_DETAIL,
            title: 'Tour Detail',
            loadChildren: () =>
              import('./modules/tour-detail/tour-detail.routes').then(
                ({ TOUR_DETAIL_ROUTES }) => TOUR_DETAIL_ROUTES
              ),
          },

          {
            path: AppRoutes.MY_TOUR,
            title: 'My Tour',
            loadChildren: () =>
              import('./modules/my-tour/my-tour.route').then(
                ({ MY_TOUR_ROUTES }) => MY_TOUR_ROUTES
              ),
            canActivate: [PagePermissionGuard],
          },
          {
            path: AppRoutes.MY_PLACE,
            title: 'My Place',
            loadChildren: () =>
              import('./modules/my-place/my-place.route').then(
                ({ MY_PLACE_ROUTES }) => MY_PLACE_ROUTES
              ),
            canActivate: [PagePermissionGuard],
          },
          {
            path: AppRoutes.MY_BOOKING,
            title: 'My Booking',
            loadChildren: () =>
              import('./modules/my-booking/my-booking.route').then(
                ({ MY_BOOKING_ROUTES }) => MY_BOOKING_ROUTES
              ),
            canActivate: [PagePermissionGuard],
          },
          {
            path: `${AppRoutes.MY_BOOKING_DETAIL}/:id`,
            title: 'My Booking detail',
            loadComponent: () =>
              import(
                './modules/my-booking/my-tour-booking/my-tour-booking-details/my-tour-booking-details.component'
              ).then(({ MyTourBookingDetailsComponent }) => MyTourBookingDetailsComponent),
          },
          {
            path: AppRoutes.SAVED_LIST,
            title: 'Saved List',
            loadChildren: () =>
              import('./modules/saved-list/saved-list.routers').then(
                ({ SAVED_ROUTE }) => SAVED_ROUTE
              ),
          },
          {
            path: AppRoutes.JOURNEY,
            title: 'Journey',
            loadComponent: () =>
              import('./layout/page-not-found/page-not-found.component').then(
                ({ PageNotFoundComponent }) => PageNotFoundComponent
              ),
          },
          {
            path: AppRoutes.FOOD,
            title: 'Food',
            loadComponent: () =>
              import('./layout/page-not-found/page-not-found.component').then(
                ({ PageNotFoundComponent }) => PageNotFoundComponent
              ),
          },
          {
            path: AppRoutes.SHOPPING,
            title: 'Shopping',
            loadComponent: () =>
              import('./layout/page-not-found/page-not-found.component').then(
                ({ PageNotFoundComponent }) => PageNotFoundComponent
              ),
          },
          {
            path: '',
            title: 'Tour',
            component: TourLayoutComponent,
            children: [
              {
                path: '',
                loadChildren: () =>
                  import('./modules/create-tour/create-tour.routes').then(
                    ({ CREATE_TOUR_ROUTES }) => CREATE_TOUR_ROUTES
                  ),
                title: 'Create tour',
              },
              {
                path: AppRoutes.TOUR_LIST,
                loadChildren: () =>
                  import('./modules/tour-list/tour-list.routes').then(
                    ({ TOUR_LIST_ROUTES }) => TOUR_LIST_ROUTES
                  ),
                title: 'Tour Search',
              },
            ],
          },
          {
            path: `${AppRoutes.REVIEW}`,
            title: 'Review tour',
            loadComponent: () =>
              import('./modules/review-tour/review-tour.component').then(
                ({ ReviewTourComponent }) => ReviewTourComponent
              ),
          },
          // {
          //   path: '**',
          //   title: 'Page Not Found',
          //   loadComponent: () =>
          //     import('./layout/page-not-found/page-not-found.component').then(
          //       ({ PageNotFoundComponent }) => PageNotFoundComponent
          //     ),
          // },
          {
            path: 'loyalty-profile',
            title: 'Loyalty Program',
            loadComponent: () =>
              import('./modules/loyalty-profile/loyalty-profile.component').then(
                ({ LoyaltyProfileComponent }) => LoyaltyProfileComponent
              ),
          },
        ],
      },
      {
        path: `${AppRoutes.BOOKING}`,
        title: 'Tour Booking',
        loadChildren: () =>
          import('./modules/tour-booking/tour-booking.routes').then(
            ({ TOUR_BOOKING_ROUTES }) => TOUR_BOOKING_ROUTES
          ),
      },
      {
        path: `${AppRoutes.REQUEST_BECOME_AGENCY}`,
        canActivate: [AutoNavigateGuard],
        data: {
          showSideMenubar: false,
          isAgencyCenterPage: false,
        },
        component: OtaLayoutComponent,
        loadChildren: () =>
          import('./modules/tour-ota/components/become-agency/become-agency.routes').then(
            ({ BECOME_AGENCY_ROUTES }) => BECOME_AGENCY_ROUTES
          ),
      },
      {
        path: `${AppRoutes.AGENCY_CENTER}`,
        component: OtaLayoutComponent,
        data: {
          showSideMenubar: false,
          isAgencyCenterPage: true,
        },
        children: [
          {
            path: '',
            title: 'Agency center',
            loadComponent: () =>
              import(
                '@modules/tour-ota/components/tour-agency-center/tour-agency-center.component'
              ).then(({ TourAgencyCenterComponent }) => TourAgencyCenterComponent),
          },
        ],
      },
      {
        path: `${AppRoutes.AGENCY}/:agencyId`,
        title: 'Agency',
        loadChildren: () =>
          import('./modules/tour-ota/ota.routes').then(({ OTA_ROUTES }) => OTA_ROUTES),
        resolve: {
          data: (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
            inject(UserRolesResolverService).resolve(route, state),
        },
      },
      {
        path: `${AppRoutes.AGENCY}/:agencyId/${AppRoutes.APPROVAL_REVIEW}`,
        loadComponent: () =>
          import(
            '@modules/tour-ota/components/agency-management/components/approval-review/approval-review.component'
          ).then(({ ApprovalReviewComponent }) => ApprovalReviewComponent),
      },
      {
        path: AppRoutes.PLACE_DETAIL,
        title: 'Place Detail',
        loadComponent: () =>
          import('./modules/place-detail/place-detail.component').then(
            ({ PlaceDetailComponent }) => PlaceDetailComponent
          ),
      },
      {
        path: 'tourrating/:id',
        loadComponent: () =>
          import('./modules/tour-detail/components/approval-review/approval-review.component').then(
            ({ ApprovalReviewComponent }) => ApprovalReviewComponent
          ),
      },
      {
        path: 'tourcomment/:id',
        loadComponent: () =>
          import(
            './modules/tour-detail/components/approval-comment/approval-comment.component'
          ).then(({ ApprovalCommentComponent }) => ApprovalCommentComponent),
      },
      {
        path: AppRoutes.LOGIN,
        title: 'Login',
        loadComponent: () =>
          import('./layout/page-not-found/page-not-found.component').then(
            ({ PageNotFoundComponent }) => PageNotFoundComponent
          ),
      },
      {
        path: 'approval',
        title: 'Approval page',
        loadChildren: () =>
          import('./layout/approval-layout/approval-layout.route').then(
            ({ APPROVAL_ROUTES }) => APPROVAL_ROUTES
          ),
      },
      {
        path: '**',
        title: 'Page Not Found',
        loadComponent: () =>
          import('./layout/page-not-found/page-not-found.component').then(
            ({ PageNotFoundComponent }) => PageNotFoundComponent
          ),
      },
    ],
  },
];
