<!-- <div
  *ngIf="mediaId === undefined && CtaSigninService.isShowCTA$ | async as isShowCTA"
  class="popup-cta fixed bottom-[4.75rem] left-0 right-0 mx-[8px] md:mx-[12.5%] z-[99999] rounded-xl !p-6 overflow-hidden"
>
  <div
    class="flex flex-col gap-4 md:flex-row justify-center md:justify-between items-center md:h-3.75rem"
  >
    <div class="flex flex-col gap-2 text-white text-center md:text-left">
      <div class="text-2xl font-semibold">
        {{ 'common.component.pop_up_cta.title' | translateAsync }}
      </div>
      <div class="text-md font-medium text-gray-100">
        {{ 'common.component.pop_up_cta.detail' | translateAsync: { param: pageTitle } }}
      </div>
    </div>
    <button
      class="md:mr-4 xl:mr-12 2xl:mr-24 whitespace-nowrap rounded-lg border border-blue-600 bg-blue-600 hover:bg-blue-700 hover:border-blue-700 h-11 w-fit py-2.5 px-[18px] text-lg font-semibold leading-6 text-white"
      (click)="handleClickSignInOrSignUp()"
    >
      {{ 'common.term.sign_in' | translateAsync }}
    </button>
  </div>
  <button
    class="absolute top-1 right-1 w-9 h-9 p-2 flex justify-center items-center shadow bg-gray-100 rounded-full text-gray-700 hover:!text-[#f26a3d]"
    (click)="handleClickClose()"
  >
    <i class="sctr-icon-x-close text-xl"></i>
  </button>
</div> -->
<div
  *ngIf="mediaId && CtaSigninService.isShowCTA$ | async as isShowCTA"
  class="fixed bottom-[75px] left-0 right-0 z-[99999] flex overflow-hidden rounded-xl hover:cursor-pointer min-[540px]:mx-[30%] mx-[3%] md:mx-[30%]"
  (click)="handleClickSignInOrSignUp()"
>
  <img class="h-full w-full object-cover" [src]="mediaId | ImagePath" alt="popup-cta" />

  <button
    class="absolute right-1 top-1 flex h-3 md:h-9 !w-3 md:!w-9 items-center justify-center rounded-full bg-gray-300 p-2 text-palette-gray-700 shadow hover:!text-primary-600"
    (click)="handleClickClose()"
  >
    <i class="sctr-icon-x-close text-xl"></i>
  </button>
</div>
