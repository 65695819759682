<div class="t-rich-text" [ngClass]="{ 'tour-guide-timeline': isTourGuide }">
  <div class="t-timeline-container">
    <p-timeline [value]="events" align="left" class="flex-1">
      <ng-template pTemplate="marker" let-event>
        <span
          *ngIf="isShowPlaceOnTop; else defaultMark"
          class="min-w-[24px] min-h-[24px] bg-gray-100 rounded-full text-2xl leading-5 p-0.5 sctr-icon-marker-pin-01 text-gray-600"
          [ngClass]="{ 'p-marker': isTourGuide }"
        ></span>
        <ng-template #defaultMark>
          <div
            class="flex min-w-[24px] min-h-[24px] flex-col justify-center items-center rounded-full border-2 border-solid bg-white"
            [ngClass]="{ 'p-marker': isTourGuide }"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="8"
              viewBox="0 0 8 8"
              fill="none"
            >
              <circle [ngClass]="{ circle: isTourGuide }" cx="4" cy="4" r="4" fill="#98A2B3" />
            </svg>
          </div>
        </ng-template>
      </ng-template>
      <ng-template pTemplate="content" let-event let-index>
        <div
          class="flex flex-col gap-2"
          [ngClass]="
            isQuickView
              ? !isItineraryTimeline
                ? 't-itinerary-timeline-view'
                : 't-timeline-quick-view'
              : 't-timeline'
          "
        >
          <div class="flex justify-between min-h-[1.75rem] gap-x-2">
            <div *ngIf="isShowPlaceOnTop; then locationName; else time"></div>
            <ng-template #locationName>
              <div class="flex items-center gap-2">
                <div
                  *ngIf="event.name"
                  [ngClass]="
                    isShowPlaceOnTop
                      ? 'm-0 text-lg text-left text-primary-700 cursor-pointer'
                      : 't-timeline-content t-timeline-hyperlink'
                  "
                  [innerHTML]="event.name | safeHtml"
                  (click)="handleClickHyperlink(event.place_id)"
                ></div>
              </div>
            </ng-template>
            <ng-template #time>
              <div class="flex items-center flex-1 h-full">
                <p class="t-timeline-time" *ngIf="event.start_time === event.end_time">
                  {{ 'common.component.timeline.flexible_time' | translateAsync }}
                </p>
                <p class="t-timeline-time" *ngIf="event.start_time !== event.end_time">
                  {{ event.start_time | customTimeFormat }}
                  <span *ngIf="event.end_time"> - {{ event.end_time | customTimeFormat }} </span>
                </p>
              </div>
            </ng-template>

            <div class="flex justify-center gap-2">
              <div
                class="flex gap-1 justify-center mt-0.5"
                *ngIf="showCost && event.expense.amount"
              >
                <span class="font-semibold text-primary-600 text-md whitespace-nowrap">
                  <div *ngIf="isPreviewMode || !isConvertPrice; else defaultCurrency">
                    {{
                      event.expense.currency
                        ? (event.expense.currency | currencySymbol)
                        : ('USD' | currencySymbol)
                    }}{{ event.expense.amount | number: '1.0-2' }}
                  </div>
                  <ng-template #defaultCurrency>{{
                    event.expense.price_usd || event.expense.amount | currencyConversion
                  }}</ng-template>
                </span>
              </div>
              <div
                class="relative flex items-center justify-center gap-2 text-base font-semibold rounded-full t-play-button w-7 h-7 text-primary-700 bg-primary-50"
                (click)="handlePlayPreview(event)"
              >
                <button class="sctr-icon-solid-play"></button>
              </div>
            </div>
          </div>
          <div class="flex items-center gap-2">
            <span
              class="w-6 h-6 p-1 bg-gray-100 rounded-full"
              [ngClass]="isShowPlaceOnTop ? 'sctr-icon-clock' : 'sctr-icon-marker-pin-01'"
            ></span>
            <div *ngIf="isShowPlaceOnTop; then time; else locationName"></div>
          </div>
          <div
            *ngIf="event.description"
            class="t-timeline-content ql-editor"
            [innerHTML]="event.description | safeHtml"
          ></div>
          <!-- <div
            *ngIf="event.secondary_text"
            class="t-timeline-content"
            [innerHTML]="event.secondary_text | localizedLanguageString | safeHtml"
          ></div> -->
          <div
            *ngIf="event.galleries"
            [class]="'t-image-grid t-image-grid-' + event.galleries.length"
          >
            <div
              *ngFor="
                let image of getAllGalleriesPath(event.galleries.slice(0, 7));
                let index = index;
                let isFirstImage = first
              "
              class="t-image-grid-item"
              [ngClass]="isFirstImage ? 't-image-grid-item-thumbnail' : ''"
              (click)="onShowGalleriesDialog(getAllGalleriesPath(event.galleries), index)"
            >
              <ng-container [ngSwitch]="checkMediaType(image)">
                <ng-container *ngSwitchCase="'video'">
                  <app-media-player
                    [url]="image"
                    [isCover]="true"
                    [showToolbar]="false"
                    [isPreviewMode]="true"
                  ></app-media-player>
                </ng-container>
                <ng-container *ngSwitchDefault><img [src]="image" alt="" /></ng-container>
              </ng-container>
              <p *ngIf="event.galleries.length - 7 > 0 && index === 6" class="t-containing-images">
                +{{ event.galleries.length - 7 }}
              </p>
            </div>
          </div>
          <div *ngIf="event.placeItem" class="t-place-item-card">
            <p class="t-place-item-subheading">{{ event.placeItem.subHeading }}</p>
            <div class="relative mt-1">
              <img [src]="event.placeItem.image" alt="" class="rounded" />
              <div class="t-place-item-rating">
                {{ event.placeItem.rating }}
              </div>
            </div>
            <h3 class="t-place-item-heading">
              {{ event.placeItem.heading }}
            </h3>
            <div class="flex justify-between" *ngIf="showCost && !isPreviewMode">
              <p class="t-place-item-leading-text">
                {{ 'common.component.timeline.price' | translateAsync }}:
              </p>
              <span class="t-place-item-price">
                <div *ngIf="isPreviewMode; else defaultCurrency">
                  {{ event.placeItem.price | currency: 'USD' }}
                </div>
                <ng-template #defaultCurrency>{{
                  event.placeItem.price | currencyConversion
                }}</ng-template>
              </span>
            </div>
          </div>
        </div>
      </ng-template>
    </p-timeline>
  </div>
</div>

<app-modal
  *ngIf="isShowGalleriesDialog"
  [hidden]="!isShowGalleriesDialog"
  [showDialog]="isShowGalleriesDialog"
  (emitOnCloseDialog)="onCloseGalleriesDialog()"
  [dialogConfig]="dialogConfig"
  [spacingSize]="'sm'"
>
  <div modal-header class="pr-8 text-lg font-semibold text-gray-900 font-Inter md:text-xl">
    {{ 'common.component.timeline.image_gallery' | translateAsync }}
  </div>
  <ng-container modal-content>
    <div id="main-panel-galleria">
      <app-gallery
        [images]="images"
        [activeIndex]="activeImageGalleryIndex"
        [autoPlay]="false"
      ></app-gallery>
    </div>
  </ng-container>
</app-modal>
